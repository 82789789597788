<template>
	<div>
		<div v-if="formDetail">
			<CCard>
				<CCardBody class="technician-form">
					<div class="shop__header">
						<h5 class="mb-3"> 1. {{ setTitle }} </h5>
					</div>
					<ValidationObserver ref="form">
						<form @submit.prevent="submitForm">
							<div class="custom">
								<div class="custom__input">
									<!-- Receiving time  -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_receiving"
											name="form_receiving"
											mode="lazy"
											:rules="`requiredExport`"
										>
											<div class="form-group row required">
												<label for="receiving" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.receiving")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<date-picker
														id="receiving"
														v-model="formUpdate.received"
														value-type="format"
														type="datetime"
														class="w-100"
														:disabled="true"
														:format="formatDate"
														:time-title-format="formatDate"
														:placeholder="$t('FormsPlacehoder.date_format')"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- order ID -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_order_id"
											name="form_order_id"
										>
											<div class="form-group row required">
												<label for="orderID" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.orderID")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<div class="d-flex align-items-center border rounded-sm order__id">
														<router-link
															v-if="formUpdate.originalOrderId"
															:to="`/orders/web/${formUpdate.originalOrderId}/detail`"
															>{{ formUpdate.originalOrderId }}</router-link
														>
													</div>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- status  -->
									<div class="form-group position-relative">
										<ValidationProvider
											ref="form_status"
											v-slot="{ classes, errors }"
											name="form_status"
										>
											<div class="form-group row required">
												<label for="status" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.status")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<multiselect
														id="status"
														v-model="formUpdate.status"
														:options="listStatus"
														:close-on-select="true"
														:show-labels="false"
														:allow-empty="false"
														:searchable="true"
														:disabled="true"
														:placeholder="$t('FormsField.status')"
														label="name"
														track-by="value"
														class="custom_multiselect"
													>
														<template #noOptions>
															{{ $t("DropDown.NoOptions") }}
														</template>
														<template #noResult>
															{{ $t("DropDown.NoResult") }}
														</template>
													</multiselect>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- canel reson  -->
									<div v-if="isCancelForm" class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_reason"
											name="form_reason"
											mode="lazy"
										>
											<div class="form-group required row">
												<label for="reason" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.reason")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<textarea
														id="reason"
														v-model.trim="formUpdate.cancelReason"
														type="text"
														rows="6"
														disabled
														class="form-control remove-resize"
														:placeholder="$t('FormsField.reason')"
														autocomplete="reason"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- type  -->
									<div class="form-group position-relative">
										<ValidationProvider
											ref="form_type"
											v-slot="{ classes, errors }"
											name="form_type"
										>
											<div class="form-group row">
												<label for="type" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.type")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<multiselect
														id="type"
														v-model="formUpdate.orderTypes"
														:options="types"
														multiple
														taggable
														label="type"
														track-by="id"
														:close-on-select="true"
														:show-labels="false"
														:allow-empty="true"
														:disabled="true"
														:searchable="false"
														:placeholder="$t('FormsField.type')"
														class="custom_multiselect"
													>
														<template #noOptions>
															{{ $t("DropDown.NoOptions") }}
														</template>
														<template #noResult>
															{{ $t("DropDown.NoResult") }}
														</template>
														<!-- <template #afterList>
														<InfiniteLoading
															v-if="isAppendLoading(types)"
															:identifier="type_order_id"
															spinner="spiral"
															@infinite="loadMoreOrderType"
														>
															<span slot="no-more" class="d-none"></span>
															<span slot="no-results" class="d-none"></span>
														</InfiniteLoading>
													</template> -->
													</multiselect>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- email -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_email"
											name="form_email"
										>
											<div class="form-group row required">
												<label for="email" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.email")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<input
														id="email"
														v-model.trim="formUpdate.email"
														type="text"
														class="form-control"
														:disabled="true"
														:placeholder="$t('FormsField.email')"
														autocomplete="email"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- phone -->
									<div class="form-group position-relative">
										<ValidationProvider v-slot="{ classes, errors }" name="form_phone">
											<div class="form-group row">
												<label for="phone" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.phone")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<input
														id="phone"
														v-model.trim="formUpdate.phoneNumber"
														type="text"
														class="form-control"
														disabled
														:placeholder="$t('FormsField.phone')"
														autocomplete="phone"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- customer name -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_customer_name"
											name="form_customer_name"
										>
											<div class="form-group row">
												<label for="customer_name" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.customerName")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<input
														id="customer_name"
														v-model="formUpdate.customerName"
														type="text"
														class="form-control"
														:placeholder="$t('FormsField.customerName')"
														autocomplete="customer_name"
														disabled
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- customer ID -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_customer_ID"
											name="form_customer_ID"
										>
											<div class="form-group row">
												<label for="customer_ID" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.customerID")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<input
														id="customer_ID"
														v-model="formUpdate.customerId"
														type="text"
														class="form-control"
														:placeholder="$t('FormsField.customerID')"
														autocomplete="customer_ID"
														disabled
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- source  -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_source"
											name="form_source"
											mode="lazy"
										>
											<div class="form-group row">
												<label for="source" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.source")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<input
														id="source"
														v-model.trim="formUpdate.source"
														type="text"
														class="form-control"
														:placeholder="$t('FormsField.source')"
														autocomplete="source"
														disabled
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- title  -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_title"
											name="form_title"
										>
											<div class="form-group row required">
												<label for="title" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.title")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<input
														id="title"
														v-model.trim="formUpdate.title"
														type="text"
														class="form-control"
														:disabled="true"
														:placeholder="$t('FormsField.title')"
														autocomplete="title"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- description  -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="form_description"
											name="form_description"
											:rules="`requiredExport`"
											mode="lazy"
										>
											<div class="form-group required row">
												<label for="description" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.description")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<textarea
														id="description"
														v-model.trim="formUpdate.description"
														type="text"
														rows="6"
														:disabled="!isUpdate"
														class="form-control remove-resize"
														:placeholder="$t('FormsField.description')"
														autocomplete="description"
														:maxlength="FORM_RULES.DESCRIPTION"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- upload file -->
									<UploadFiles
										name="form update"
										:show-delete="false"
										:show-download="true"
										:show-add="false"
										:is-update="false"
										:files="formUpdate.uploadFiles"
										:is-required="false"
									/>
									<!-- admin link -->
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											ref="adminLink"
											name="adminLink"
											mode="lazy"
											:rules="`isUrl`"
										>
											<div class="form-group row">
												<label for="adminLink" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.adminLink")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<div class="position-relative">
														<input
															id="adminLink"
															v-model.trim="formUpdate.adminLink"
															type="text"
															class="form-control pr-5"
															:placeholder="$t('FormsField.adminLink')"
															:disabled="!isUpdate"
														/>
														<button
															class="px-2 btn btn-outline-custom icon"
															type="button"
															:disabled="!formUpdate.adminLink || !isUpdate"
															@click="addLink"
														>
															<font-awesome-icon
																:icon="['fas', 'plus']"
																:title="$t('Action.Add')"
															/>
														</button>
													</div>
													<div v-if="errors.length" class="error-mess">
														{{ errors[0] }}
													</div>
													<div
														v-if="
															formUpdate.adminDropboxFolderLinks &&
															formUpdate.adminDropboxFolderLinks.length
														"
														class="dropbox-list py-3"
													>
														<div
															v-for="(adminLink, index1) in formUpdate.adminDropboxFolderLinks"
															:key="index1"
															class="d-flex justify-content-between align-items-center dropbox-item mb-1"
															:class="{ 'disabled-link': !isUpdate }"
														>
															<a :href="adminLink" target="_blank" class="dropbox-url">{{
																adminLink
															}}</a>
															<button
																class="px-2 btn btn-outline-danger btn-delete"
																type="button"
																:disabled="!isUpdate"
																@click="removeLink(index1)"
															>
																<font-awesome-icon
																	:icon="['fas', 'trash-alt']"
																	:title="$t('Action.Delete')"
																/>
															</button>
														</div>
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>
									<!-- link dropbox -->
									<div v-if="disableDropboxLink" class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes }"
											ref="dropbox"
											name="linkDropbox"
											mode="lazy"
											:rules="`isUrl`"
										>
											<div class="form-group row">
												<label for="linkDropbox" class="col-md-2 col-12 col-form-label">{{
													$t("OrderFields.linkDropbox")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<div
														v-if="
															formUpdate.dropboxSharedFolderLinks &&
															formUpdate.dropboxSharedFolderLinks.length
														"
														class="dropbox-list pb-3"
													>
														<div
															v-for="(dropboxLink, index1) in formUpdate.dropboxSharedFolderLinks"
															:key="index1"
															class="d-flex justify-content-between align-items-center dropbox-item mb-1 disabled-link"
														>
															<a :href="dropboxLink" target="_blank" class="dropbox-url">{{
																dropboxLink
															}}</a>
														</div>
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- link drive -->
									<div v-if="disableDriveLink" class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes }"
											ref="drive"
											name="linkDrive"
											mode="lazy"
											:rules="`isUrl`"
										>
											<div class="form-group row">
												<label for="linkDrive" class="col-md-2 col-12 col-form-label">{{
													$t("OrderFields.linkDrive")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<div
														v-if="
															formUpdate.driveSharedFolderLinks &&
															formUpdate.driveSharedFolderLinks.length
														"
														class="dropbox-list pb-3"
													>
														<div
															v-for="(driveLink, index2) in formUpdate.driveSharedFolderLinks"
															:key="index2"
															class="d-flex justify-content-between align-items-center dropbox-item mb-1 disabled-link"
														>
															<a :href="driveLink" target="_blank" class="dropbox-url">{{
																driveLink
															}}</a>
														</div>
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<!-- package -->
									<CRow>
										<CCol col="6">
											<div class="form-group position-relative">
												<div class="form-group row">
													<label for="package" class="col-md-4 col-12 col-form-label">{{
														$t("FormsField.package")
													}}</label>
													<div :class="['col-md-8 col-12']">
														<CCard class="border">
															<CCardBody>
																<div v-if="formUpdate.packageName" class="font-weight-bold py-2">{{
																	capitalize(formUpdate.packageName)
																}}</div>
																<div class="d-flex justify-content-between align-items-center py-2">
																	<span>{{ $t("FormsField.amount") }}</span>
																	<span>{{ formUpdate.amount }}</span>
																</div>
																<div
																	class="d-flex justify-content-between align-items-center py-2"
																	:class="{ 'border-bottom': formUpdate.packagePrice }"
																>
																	<span>{{ $t("FormsField.time") }}</span>
																	<span>{{ formUpdate.packageDeliveryTime }}</span>
																</div>
																<div
																	v-if="formUpdate.packagePrice"
																	class="d-flex justify-content-end align-items-center py-2"
																>
																	<span class="font-weight-bold"
																		>${{ formatPricePackage(formUpdate.packagePrice) }}</span
																	>
																</div>
															</CCardBody>
														</CCard>
													</div>
												</div>
											</div>
										</CCol>
										<CCol col="6">
											<div class="form-group position-relative">
												<div class="form-group row">
													<label for="price" class="col-md-4 col-12 col-form-label text-right">{{
														$t("FormsField.price")
													}}</label>
													<div :class="['col-md-8 col-12']">
														<CCard class="border">
															<CCardBody>
																<div
																	v-if="!formUpdate.isRevision"
																	class="d-flex justify-content-between align-items-center py-2"
																>
																	<span>{{ capitalize(formUpdate.packageName) }} package</span>
																	<span>{{ formatPricePackage(formUpdate.packagePrice) }}</span>
																</div>
																<div
																	v-if="showTimeDelivery"
																	class="d-flex justify-content-between align-items-center py-2"
																>
																	<span>{{ $t("FormsField.extra") }}</span>
																	<span>{{ formUpdate.fastDeliveryPrice }}</span>
																</div>
																<div
																	v-if="showDiscount"
																	class="d-flex justify-content-between align-items-center py-2"
																>
																	<span>{{ $t("FormsField.discount") }}</span>
																	<span>{{ formUpdate.discounted }}</span>
																</div>
																<div
																	v-if="formUpdate.isRevision && formUpdate.originalPrice >= 0"
																	class="d-flex justify-content-between align-items-center py-2"
																>
																	<span>{{ $t("FormsField.originalPrice") }}</span>
																	<span>{{ formUpdate.originalPrice }}</span>
																</div>
																<div
																	v-if="formUpdate.requestPrice"
																	class="d-flex justify-content-between align-items-center py-2"
																>
																	<span>{{ $t("FormsField.requestPrice") }}</span>
																	<span>{{ formatPricePackage(formUpdate.requestPrice) }}</span>
																</div>
																<div
																	class="d-flex justify-content-between align-items-center py-2 font-weight-bold"
																	:class="{ 'border-top': showBorderFinalPrice }"
																>
																	<span>{{ $t("FormsField.finalPrice") }}</span>
																	<span>${{ formatPricePackage(formUpdate.finalPrice) }}</span>
																</div>
															</CCardBody>
														</CCard>
													</div>
												</div>
											</div>
										</CCol>
									</CRow>
								</div>
							</div>

							<CRow class="text-right">
								<CCol>
									<button
										v-if="showButtonCreate"
										type="button"
										class="px-4 ml-2 btn btn-outline-custom"
										@click="createOrder"
									>
										{{ $t("Button.CreateOrder") }}
									</button>
									<button
										v-if="showButtonUpdate"
										type="button"
										class="px-4 ml-2 btn btn-outline-custom"
										@click="addUpdateOrder"
									>
										{{ $t("Button.AddUpdate") }}
									</button>
									<button v-if="isUpdate" class="px-4 ml-2 btn btn-outline-custom" type="submit">
										{{ $t("Button.Save") }}
									</button>
									<button
										v-if="showCancelForm"
										type="button"
										class="ml-2 btn btn-outline-danger"
										@click="cancelForm"
									>
										{{ $t("Button.CancelForm") }}
									</button>
									<button
										v-if="isUpdate"
										type="button"
										class="ml-2 btn btn-outline-danger"
										@click="cancel"
									>
										{{ $t("Button.Back") }}
									</button>
									<button
										v-if="!isUpdate"
										type="button"
										class="ml-2 btn btn-outline-danger"
										@click="close"
									>
										{{ $t("Button.Close") }}
									</button>
								</CCol>
							</CRow>
						</form>
					</ValidationObserver>
				</CCardBody>
			</CCard>
			<CCard>
				<CCardBody>
					<!-- request -->
					<div class="request">
						<h5 class="mb-3"> 2. Request </h5>
						<CCard v-if="isUpdate" class="border">
							<CCardBody>
								<ValidationObserver ref="formRequest" tag="form">
									<CRow>
										<CCol>
											<div class="form-group position-relative">
												<ValidationProvider
													v-slot="{ classes, errors }"
													name="form_request_time"
													:rules="{
														isValidFormRequest: isValidFormRequest,
														valueEqualZero: true,
														isNumber: true,
													}"
												>
													<div class="form-group row">
														<label for="package" class="col-md-4 col-12 col-form-label">{{
															$t("FormsField.deliveryTime")
														}}</label>
														<div :class="['col-md-8 col-12', classes]">
															<input
																v-model.trim="timeOfDelivery"
																type="text"
																class="form-control pr-5"
																:placeholder="$t('FormsField.deliveryTime')"
																:disabled="!canCreate"
																:maxlength="FORM_REQUEST_RULES.TIME"
																@keydown="checkValidPhone($event)"
																@paste="handleOnPaste"
															/>
															<div v-if="errors.length" class="error-mess">
																{{ errors[0] }}
															</div>
														</div>
													</div>
												</ValidationProvider>
											</div>
										</CCol>
										<CCol>
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="form_request_amount"
												:rules="{
													isValidFormRequest: isValidFormRequest,
													requiredExport: requireAmount,
													valueEqualZero: true,
													isNumber: true,
												}"
											>
												<div class="form-group position-relative">
													<div class="form-group row">
														<label
															for="package"
															class="col-md-4 col-12 col-form-label text-right"
															>{{ $t("FormsField.amount") }}</label
														>
														<div :class="['col-md-8 col-12', classes]">
															<input
																v-model.trim="amountRequest"
																type="text"
																class="form-control pr-5"
																:placeholder="$t('FormsField.amount')"
																:disabled="!canCreate"
																:maxlength="FORM_REQUEST_RULES.AMOUNT"
																@keydown="checkValidPhone($event)"
																@paste="handleOnPaste"
															/>
															<div v-if="errors.length" class="error-mess">
																{{ errors[0] }}
															</div>
														</div>
													</div>
												</div>
											</ValidationProvider>
										</CCol>
									</CRow>
									<CRow>
										<CCol>
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="form_request_price"
												:rules="{
													isValidFormRequest: isValidFormRequest,
													valueEqualZero: true,
													isCurrency: true,
												}"
											>
												<div class="form-group position-relative">
													<div class="form-group row">
														<label for="package" class="col-md-4 col-12 col-form-label">{{
															$t("FormsField.offerPrice")
														}}</label>
														<div :class="['col-md-8 col-12', classes]">
															<input
																id="offer-price"
																v-model.trim="offerPrice"
																type="text"
																class="form-control pr-5"
																:placeholder="$t('FormsField.offerPrice')"
																:disabled="!canCreate"
																:maxlength="FORM_REQUEST_RULES.OFFER_PRICE"
																@keydown="checkValidNumber($event)"
																@paste="handleOnPaste"
																@input="formatCurrency"
															/>
															<div v-if="errors.length" class="error-mess">
																{{ errors[0] }}
															</div>
														</div>
													</div>
												</div>
											</ValidationProvider>
										</CCol>
										<CCol>
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="form_request_order_type"
												:rules="{ isValidFormRequest: isValidFormRequest }"
											>
												<div class="form-group position-relative">
													<div class="form-group row">
														<label
															for="type_request"
															class="col-md-4 col-12 col-form-label text-right"
															>{{ $t("FormsField.orderType") }}</label
														>
														<div :class="['col-md-8 col-12', classes]">
															<multiselect
																id="type_request"
																v-model="orderTypeRequest"
																:options="types"
																label="type"
																track-by="id"
																:close-on-select="true"
																:show-labels="false"
																:allow-empty="true"
																:searchable="true"
																:placeholder="$t('FormsField.type')"
																:disabled="!canCreate"
																class="custom_multiselect"
																@select="selectTypes"
																@remove="clearType"
															>
																<template #noOptions>
																	{{ $t("DropDown.NoOptions") }}
																</template>
																<template #noResult>
																	{{ $t("DropDown.NoResult") }}
																</template>
																<!-- <template #afterList>
																	<InfiniteLoading
																		v-if="isAppendLoading(types)"
																		:identifier="type_order_id"
																		spinner="spiral"
																		@infinite="loadMoreOrderType"
																	>
																		<span slot="no-more" class="d-none"></span>
																		<span slot="no-results" class="d-none"></span>
																	</InfiniteLoading>
																</template>
																<template slot="clear">
																	<div
																		v-if="orderTypeRequest"
																		class="multiselect__clear"
																		@mousedown.prevent.stop="clearType"
																	>
																		<font-awesome-icon
																			:icon="['fas', 'times']"
																			:title="$t('Action.Clear')"
																		/>
																	</div>
																</template> -->
															</multiselect>
															<div v-if="errors.length" class="error-mess">
																{{ errors[0] }}
															</div>
														</div>
													</div>
												</div>
											</ValidationProvider>
										</CCol>
									</CRow>
									<div class="form-group position-relative">
										<ValidationProvider
											v-slot="{ classes, errors }"
											name="form_request_note"
											:rules="`requiredExport`"
										>
											<div class="form-group row">
												<label for="package" class="col-md-2 col-12 col-form-label">{{
													$t("FormsField.note")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<input
														v-model.trim="note"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('FormsField.note')"
														:disabled="!canCreate"
														:maxlength="FORM_REQUEST_RULES.NOTE"
													/>
													<div v-if="errors.length" class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>
									<CRow class="text-right">
										<CCol>
											<button
												v-if="canCreate"
												type="button"
												class="px-4 mt-2 btn btn-outline-custom"
												@click="createFormRequest"
											>
												{{ $t("Button.Send") }}
											</button>
											<button
												v-else
												type="button"
												class="px-4 mt-2 btn btn-outline-danger"
												@click="cancelFormRequest"
											>
												{{ $t("Button.Cancel") }}
											</button>
										</CCol>
									</CRow>
								</ValidationObserver>
							</CCardBody>
						</CCard>
						<FormRequestTable :id="id" />
					</div>
				</CCardBody>
			</CCard>
			<CustomModal
				v-if="isCancelFormRequest"
				:title="$t('FormMessages.cancelRequest.header')"
				:submit-text="$t('Button.Confirm')"
				@submit-modal="confirmPopupCancelRequest"
				@cancel-modal="cancelPopupCancelRequest"
			>
				<template #content>
					{{ $t("FormMessages.cancelRequest.body") }}
				</template>
			</CustomModal>
			<CustomModal
				v-if="showErrCancelRequest"
				:submit-text="$t('Button.OK')"
				:is-show-cancel="false"
				@submit-modal="reloadPage"
				@cancel-modal="reloadPage"
			>
				<template #content>
					{{ $t("FormMessages.cancelRequest.error") }}
				</template>
			</CustomModal>
		</div>
	</div>
</template>

<script>
import { capitalize, round, isInteger, cloneDeep } from "lodash-es"
import {
	STATUS_FORM,
	DATE_FORMAT,
	FORM_RULES,
	IGNORE_FORM_STATUS,
	FORM_REQUEST_RULES,
	REGEX,
} from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
import { GET_TYPES_ORDER } from "../../typeOrder/store/action-types"
import { CREATE_FORM_REQUEST, CANCEL_FORM_REQUEST } from "../store/action-types"
import { RESET_STATE } from "../../typeOrder/store/mutation-types"
import { FORM_TYPE_CLICK } from "../../../shared/plugins/constants"
import { SET } from "../store/mutation-types"
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("forms")
const {
	mapState: orderTypeState,
	mapActions: orderTypeActions,
	mapMutations: orderTypeMutations,
} = createNamespacedHelpers("typeOrder")
export default {
	name: "FormWeb",
	components: {
		UploadFiles: () => import("@/shared/components/UploadFiles.vue"),
		FormRequestTable: () => import("./FormRequestTable.vue"),
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			formatDate: DATE_FORMAT,
			FORM_REQUEST_RULES,
			FORM_RULES,
			capitalize,
			isCancelFormRequest: false,
			showErrCancelRequest: false,
			formUpdate: {
				received: null,
				orderTypes: [],
				status: null,
				customerId: null,
				customerName: null,
				title: null,
				description: null,
				uploadFiles: null,
				cancelReason: null,
				dropboxSharedFolderLinks: [],
				driveSharedFolderLinks: [],
				adminDropboxFolderLinks: [],
				phoneNumber: null,
				adminLink: null,
			},
			timeOfDelivery: null,
			offerPrice: null,
			note: null,
			amountRequest: null,
			listStatus: [
				{
					name: this.$t("FormsFilterName.Responded"),
					value: STATUS_FORM.RESPONDED,
				},
				{
					name: this.$t("FormsFilterName.Unreviewed"),
					value: STATUS_FORM.UNREVIEW,
				},
				{
					name: this.$t("FormsFilterName.Requested"),
					value: STATUS_FORM.REQUESTED,
				},
				{
					name: this.$t("FormsFilterName.Reviewed"),
					value: STATUS_FORM.REVIEWED,
				},
				{
					name: this.$t("FormsFilterName.Cancelled"),
					value: STATUS_FORM.CANCELLED,
				},
			],
			orderTypeRequest: null,
		}
	},
	computed: {
		...mapState(["formDetail", "canCreate", "requestOpen", "requests", "canCancel"]),
		...orderTypeState({
			types: "typesOrder",
		}),
		setTitle() {
			return `${this.$t("FormsTitle.FormUpdate")} ${this.formDetail?.displayId}`
		},
		disableDropboxLink() {
			if (!this.formDetail.dropboxSharedFolderLinks?.length) {
				return false
			}
			return true
		},
		disableDriveLink() {
			if (!this.formDetail.driveSharedFolderLinks?.length) {
				return false
			}
			return true
		},
		showCancelForm() {
			if (!IGNORE_FORM_STATUS.includes(this.formDetail.status)) {
				return true
			}
			return false
		},
		isValidFormRequest() {
			if (
				!this.timeOfDelivery &&
				!this.amountRequest &&
				!this.offerPrice &&
				!this.orderTypeRequest
			) {
				return true
			}
			return false
		},
		isCancelForm() {
			if (!this.isUpdate && this.formDetail?.status === STATUS_FORM.CANCELLED) {
				return true
			}
			return false
		},
		showButtonUpdate() {
			if (this.formDetail.originalOrderId && this.canCreate) {
				return true
			}
			return false
		},
		showButtonCreate() {
			if (!this.formDetail.originalOrderId && this.canCreate) {
				return true
			}
			return false
		},
		showTimeDelivery() {
			if (this.formUpdate.fastDeliveryPrice && !this.formUpdate.isRevision) {
				return true
			}
			return false
		},
		showDiscount() {
			if (this.formUpdate.discounted && !this.formUpdate.isRevision) {
				return true
			}
			return false
		},
		showBorderFinalPrice() {
			if (
				this.formUpdate?.requestPrice ||
				this.showDiscount ||
				this.showTimeDelivery ||
				!this.formUpdate?.isRevision
			) {
				return true
			}
			return false
		},
		requireAmount() {
			if (this.formUpdate?.isRevision && this.offerPrice && !this.amountRequest) return true
			return false
		},
	},
	watch: {
		formDetail: {
			handler() {
				this.setUpData()
			},
			deep: true,
		},
		canCreate(val) {
			if (!val) {
				this.timeOfDelivery = this.requestOpen?.timeOfDelivery
				this.amountRequest = this.requestOpen?.amount
				this.offerPrice = this.formatOfferPrice(this.requestOpen?.offerPrice)
				this.note = this.requestOpen?.note
				if (this.requestOpen?.orderType) {
					this.orderTypeRequest = { type: this.requestOpen?.orderType }
				}
			} else {
				this.timeOfDelivery = null
				this.amountRequest = null
				this.offerPrice = null
				this.note = null
				this.orderTypeRequest = null
			}
		},
	},
	mounted() {
		this.setUpData()
	},
	async beforeDestroy() {
		await this.RESET_STATE()
		this.isShowFormChanges = false
	},
	methods: {
		...orderTypeActions({ GET_TYPES_ORDER }),
		...mapActions({ CREATE_FORM_REQUEST, CANCEL_FORM_REQUEST }),
		...orderTypeMutations({ RESET_STATE }),
		...mapMutations({ SET }),
		async setUpData() {
			// for update technician
			if (this.formDetail) {
				this.formUpdate = cloneDeep(this.formDetail)

				if (this.formDetail.status === STATUS_FORM.CANCELLED) {
					this.formUpdate.status = {
						name: this.$t("FormsFilterName.Cancelled"),
						value: STATUS_FORM.CANCELLED,
					}
				} else {
					this.formUpdate.status = this.listStatus.find(
						status => status.value === this.formDetail.status,
					)
				}

				if (!this.formDetail.dropboxSharedFolderLinks) {
					this.formUpdate.dropboxSharedFolderLinks = []
				}
				if (!this.formDetail.driveSharedFolderLinks) {
					this.formUpdate.driveSharedFolderLinks = []
				}
				if (!this.formDetail.adminDropboxFolderLinks) {
					this.formUpdate.adminDropboxFolderLinks = []
				}

				if (this.formDetail.phoneNumber) {
					this.formUpdate.phoneNumber = `+${this.formUpdate.phoneCode}${this.formUpdate.phoneNumber}`
				}

				this.formUpdate.uploadFiles = [...this.formUpdate.attachments]
			}
		},
		submitForm: async function (type) {
			const valid = await this.$refs.form.validate()
			if (!valid) {
				const errors = Object.entries(this.$refs.form.errors)
				for (const element of errors) {
					if (element[1]?.length) {
						const refName = element[0]
						let elementError = null
						if (refName !== "upload") {
							elementError = this.$refs[refName]?.$el
						} else {
							elementError = document.querySelector("#upload")
						}
						elementError.scrollIntoView({
							behavior: "smooth",
							block: "center",
						})
						return
					}
				}

				return
			}

			const params = {
				id: parseFloat(this.id),
				description: this.formUpdate.description,
				adminDropboxFolderLinks: this.formUpdate.adminDropboxFolderLinks.length
					? this.formUpdate.adminDropboxFolderLinks
					: [],
			}

			if (type) {
				params["typeClick"] = type
			}

			this.$emit("submit", params)
		},
		cancelForm: function () {
			this.$emit("cancelForm")
		},
		close() {
			this.$emit("close")
		},
		cancel() {
			this.$emit("cancel")
		},
		cancelFormRequest() {
			this.isCancelFormRequest = true
			this.$store.commit("set", ["modalShow", true])
		},
		selectTypes(value) {
			this.orderTypeRequest = value
		},
		clearType() {
			this.orderTypeRequest = null
		},
		async createFormRequest() {
			const valid = await this.$refs.formRequest.validate()

			if (!valid) return

			const params = {
				formId: this.id,
				amount: this.amountRequest || null,
				timeOfDelivery: this.timeOfDelivery || null,
				offerPrice: this.offerPrice ? this.offerPrice.split(",").join("") : null,
				note: this.note,
				orderTypeId: this.orderTypeRequest?.id || null,
			}

			await this.CREATE_FORM_REQUEST(params)
			this.$emit("create-request")
		},
		async createOrder() {
			const validateForm = await this.$refs.form.validate()

			if (!validateForm) {
				const errors = Object.entries(this.$refs.form.errors)
				for (const element of errors) {
					if (element[1]?.length) {
						const refName = element[0]
						let elementError = null
						elementError = this.$refs[refName]?.$el
						elementError?.scrollIntoView({
							behavior: "smooth",
							block: "center",
						})
						return
					}
				}
				return
			}
			await this.submitForm(FORM_TYPE_CLICK.CREATE)
		},
		async addUpdateOrder() {
			const validateForm = await this.$refs.form.validate()

			if (!validateForm) {
				const errors = Object.entries(this.$refs.form.errors)
				for (const element of errors) {
					if (element[1]?.length) {
						const refName = element[0]
						let elementError = null
						elementError = this.$refs[refName]?.$el
						elementError.scrollIntoView({
							behavior: "smooth",
							block: "center",
						})
						return
					}
				}
				return
			}
			this.SET(["originalId", this.formDetail.originalOrderId])
			await this.submitForm(FORM_TYPE_CLICK.UPDATE)
		},
		showMessageFormChanges() {
			this.$store.commit("set", ["modalShow", true])
			this.isShowFormChanges = true
		},
		async addLink() {
			const validate = await this.$refs.adminLink.validate()
			const value = this.$refs.adminLink.value

			if (!validate.valid || !value) {
				return
			}

			this.formUpdate.adminDropboxFolderLinks.unshift(this.formUpdate.adminLink)
			this.formUpdate.adminLink = null
		},
		removeLink(index) {
			this.formUpdate.adminDropboxFolderLinks.splice(index, 1)
		},
		async confirmPopupCancelRequest() {
			const params = {
				requestId: this.requestOpen.id,
				form_id: this.id,
			}
			const res = await this.CANCEL_FORM_REQUEST(params)
			if (res.ok) {
				this.cancelPopupCancelRequest()
				this.$refs.formRequest.reset()
				this.$emit("cancel-request")
			} else {
				const { error } = res

				if (error.code === "FORM_REQUEST_NOT_FOUND") {
					this.cancelPopupCancelRequest()
					this.showErrCancelRequest = true
					this.$store.commit("set", ["modalShow", true])
				}
			}
		},
		reloadPage() {
			this.showErrCancelRequest = false
			this.$store.commit("set", ["modalShow", false])
			window.location.reload()
		},
		cancelPopupCancelRequest() {
			this.isCancelFormRequest = false
			this.$store.commit("set", ["modalShow", false])
		},
		formatCurrency() {
			// appends $ to value, validates decimal side
			// and puts cursor back in right position.

			const input = document.querySelector("#offer-price")

			// get input value
			let input_val = input.value || this.offerPrice

			const valid = REGEX.IS_CURRENCY.test(input_val)

			if (!valid) return

			// don't validate empty input
			if (input_val === "" || parseFloat(input_val) === 0) {
				return
			}

			// original length
			let original_len = input_val.length

			// initial caret position
			let caret_pos = input.selectionStart
			// check for decimal
			if (input_val.indexOf(".") < 0) {
				// no decimal entered
				// add commas to number
				// remove all non-digits
				input_val = this.formatNumber(input_val)
				input_val = input_val
			}

			if (input_val.length > FORM_REQUEST_RULES.OFFER_PRICE) {
				input_val = this.formatNumber(input_val.split("").slice(0, 5).join(""))
				input.value = input_val
				// this.formUpdate.offerPrice = input_val
			}

			// send updated string to input
			input.value = input_val
			this.offerPrice = input_val

			// put caret back in the right position
			let updated_len = input_val.length
			caret_pos = updated_len - original_len + caret_pos
			input.setSelectionRange(caret_pos, caret_pos)
		},
		formatPricePackage(price) {
			if (!price) return price

			if (price < 0) return 0

			let priceFormat = null

			if (isInteger(price)) {
				priceFormat = this.formatNumber(round(price, 2).toString())
			} else {
				let decimal_pos = price.toString().split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				right_side = right_side?.substring(0, 2)
				priceFormat = left_side + "." + right_side
			}
			return priceFormat
		},
		formatOfferPrice(price) {
			if (!price) return null

			let priceFormat = null

			if (isInteger(price)) {
				priceFormat = this.formatNumber(price.toString())
			} else {
				let decimal_pos = price.toString().split(".")

				let left_side = decimal_pos[0]
				let right_side = decimal_pos[1]

				left_side = this.formatNumber(left_side)
				// right_side = right_side?.substring(0, 2)
				priceFormat = left_side + "." + right_side
			}
			return priceFormat
		},
		formatNumber(n) {
			if (!n) return null
			// format number 1000000 to 1,234,567
			return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.custom__input {
	width: 100%;
	margin: auto;
}
.remove-resize {
	resize: none;
}
.options__border {
	max-height: 300px;
	border: 1px solid $border-input;
	border-top: none;
	background: $white;
	z-index: 10;
	width: calc(100% - 30px);
	position: absolute;
	overflow-y: auto;

	.wrap {
		margin: 6px;
		padding: 6px;

		&:hover {
			background: rgba(0, 134, 217, 0.05) !important;
			color: #0086d9 !important;
		}

		.item__hover {
			cursor: pointer;
			border-bottom: 1px solid $grey-4;
		}

		&:last-child {
			.item__hover {
				border: none;
				padding-bottom: 0;
			}
		}
	}
}

.icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 10;
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox-url {
	width: calc(100% - 60px);
}

.btn-delete {
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox {
	&-item {
		padding-left: 0.75rem;
		border: 1px solid $border-input;
		border-radius: 0.25rem;
		min-height: 35px;
	}
}

.disabled-link {
	background: $grey-5;
	cursor: not-allowed;
}

.order__id {
	padding: 0.375rem 0.75rem;
	background: $grey-5;
	cursor: not-allowed;
	height: 35px;
}
</style>
